import type { Component, JSX } from 'solid-js';

export const Laptop: Component<JSX.SvgSVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg width="1200pt" fill="currentColor" height="1200pt" viewBox="0 0 1200 1200" {...props}>
      <path d="m1011.7 823.56v-23.52h-23.52v-47.039h-23.52v-47.16h-23.52v-23.52h23.52v-352.92h-23.52v-23.52h-23.52v-23.52h-282.36l-23.523-0.003906h-329.39v23.52h-23.52v23.52h-23.52v94.078l-0.007813 23.523v235.32h23.52v23.52h-23.52v47.164h-23.52v47.039h-23.52v47.039l-23.52 0.003906v47.039h23.52v23.52h823.43v-23.52h23.52v-47.039h-23.52zm-729.36-70.559h23.52v-23.641h23.52v23.641h23.52v-23.641h23.52l0.003906 23.641h23.52v-23.641h23.52v23.641h23.52v-23.641h23.641v23.641h23.52v-23.641h23.52v23.641h23.52v-23.641h23.52v23.641h-23.52v23.52h-23.52v-23.52h-23.52v23.52h-23.52v-23.52h-23.641v23.52h-23.52v-23.52h-23.52v23.52h-23.52v-23.52h-23.52v23.52h-23.52l-0.003906-23.52h-23.52v23.52h-23.52zm0-141.24v-235.32h23.52v-23.52h588.23v23.52h23.52v47.039l0.011719 23.52v188.28h-23.52v23.52h-258.84l-23.523 0.003906h-305.88v-23.52h-23.52zm541.2 141.24h-47.039v-23.641h47.039zm-188.28 0h23.52v-23.641h23.52v23.641h23.52v-23.641h23.52v23.641h-23.52v23.52h-23.52v-23.52h-23.52v23.52h-23.52zm235.32 47.039h23.52v23.52h-23.52zm-352.92 23.52h-23.52v-23.52h47.039v23.52zm70.559-517.68h47.039v23.52h-70.555v-23.52zm-352.92 541.2h23.52v23.52h-47.039v-23.52zm399.96 47.039h-94.078v-23.52h23.52v-23.52h70.559v23.52h23.52v23.52z" />
      <path d="m753 470.64h23.52v23.52h-23.52z" />
      <path d="m729.36 494.16h23.641v23.52h-23.641z" />
      <path d="m705.84 517.68h23.52v23.52h-23.52z" />
      <path d="m447 399.96h23.641v23.52h-23.641z" />
      <path d="m423.48 423.48h23.52v23.52h-23.52z" />
      <path d="m399.96 447h23.52v23.641h-23.52z" />
      <path d="m399.96 376.44h23.52v23.52h-23.52z" />
      <path d="m376.44 470.64h23.52v23.52h-23.52z" />
      <path d="m376.44 399.96h23.52v23.52h-23.52z" />
      <path d="m352.92 494.16h23.52v23.52h-23.52z" />
      <path d="m352.92 423.48h23.52v23.52h-23.52z" />
      <path d="m329.4 517.68h23.52v23.52h-23.52z" />
      <path d="m329.4 447h23.52v23.641h-23.52z" />
    </svg>
  );
};
