import type { Component, JSX } from 'solid-js';

export const Magnifier: Component<JSX.SvgSVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg width="1200pt" fill="currentColor" height="1200pt" viewBox="0 0 1200 1200" {...props}>
      <path d="m583.37 267.34 99.785 99.785 16.629 16.633 16.719 16.715 33.262 33.262 16.629 16.633 33.348-33.348-16.629-16.633-16.633-16.629-16.629-16.633-16.719-16.715-16.629-16.633-16.633-16.629-16.629-16.633-16.633-16.633-16.633-16.629-16.629-16.633-16.633-16.629-16.629 16.629z" />
      <path d="m733.13 483.62 16.633 16.629 16.629 16.633 16.633-16.633 16.633-16.629-16.633-16.633-16.633-16.629z" />
      <path d="m550.11 300.6 16.633-16.633 16.633-16.629-33.266-33.266-16.629 16.633-16.633 16.633 16.633 16.629z" />
      <path d="m733.13 550.15 33.262 33.262 16.633-16.629 16.633-16.633-16.633-16.629-16.633-16.633-16.629 16.633z" />
      <path d="m483.58 300.6 33.262-33.262-16.629-16.633-16.633-16.633-16.633 16.633-16.629 16.633z" />
      <path d="m733.13 616.67-16.629 16.633-49.895 49.895-16.633 16.629-16.715 16.719-16.633 16.629-16.629 16.633-16.633 16.629 33.262 33.262 16.633-16.629 33.262-33.262 16.715-16.719 16.633-16.629 16.633 16.629 16.629 16.633-16.629 16.633 199.66 199.66 16.633-16.629 16.629 16.629 16.633-16.629-16.633-16.633 16.633-16.629-16.633-16.633-16.629-16.633-16.633-16.629-16.629-16.633-16.633-16.629-16.629-16.633-16.633-16.629-16.633-16.633-16.629-16.633-16.633-16.629-16.629-16.633-16.719-16.715-16.629 16.629-16.633-16.629-16.629-16.633 33.262-33.262 16.629-16.633 16.633-16.629 16.633-16.633-16.633-16.629-16.633-16.633-16.629 16.633z" />
      <path d="m433.69 417.02-16.633 16.633-16.633-16.633 16.633-16.633z" />
      <path d="m433.69 450.28-16.633 16.633-16.629-16.629 16.633-16.633z" />
      <path d="m433.69 483.54-16.715 16.715-16.633-16.633 16.715-16.715z" />
      <path d="m433.6 516.89-16.633 16.633-16.629-16.629 16.633-16.633z" />
      <path d="m333.82 450.36 16.633-16.633 16.633 16.633-16.633 16.633z" />
      <path d="m433.6 550.15-16.629 16.629-16.633-16.633 16.629-16.629z" />
      <path d="m333.82 483.62 16.629-16.629 16.633 16.633-16.629 16.629z" />
      <path d="m300.55 417.1 16.629-16.633 16.719-16.715 16.629-16.633 49.895-49.895 16.633-16.629 16.629-16.633 16.633-16.629-33.348-33.348-16.633 16.629-16.629 16.633-16.633 16.629-16.629 16.633-16.633 16.633-16.633 16.629-16.629 16.633-66.609 66.609 33.348 33.348 16.629-16.633z" />
      <path d="m433.61 583.41-16.633 16.633-16.633-16.633 16.633-16.633z" />
      <path d="m333.82 516.89 16.633-16.633 16.629 16.629-16.633 16.633z" />
      <path d="m550.11 733.18-16.629 16.633-16.633 16.629 33.262 33.262 16.633-16.629 16.629-16.633z" />
      <path d="m433.6 616.67-16.633 16.633-16.629-16.629 16.633-16.633z" />
      <path d="m333.82 550.15 16.633-16.633 16.633 16.633-16.633 16.633z" />
      <path d="m300.55 483.62-33.262-33.262-16.633 16.629-16.629 16.633 33.262 33.262 16.629-16.633z" />
      <path d="m333.82 583.41 16.629-16.629 16.629 16.629-16.629 16.629z" />
      <path d="m483.58 733.18-33.262 33.262 16.629 16.633 16.633 16.629 16.633-16.629 16.629-16.633z" />
      <path d="m300.55 550.15-16.633-16.629-16.629-16.633-33.262 33.262 16.629 16.633 16.633 16.629z" />
      <path d="m417.06 733.18-33.348-33.348-16.633-16.629-16.629-16.633-16.633-16.633-16.633-16.629-16.629-16.633-16.633-16.629-16.629-16.633-33.262 33.262 16.629 16.633 83.156 83.156 16.633 16.629 16.715 16.719 33.262 33.262 16.633 16.629 33.262-33.262-16.633-16.629z" />
    </svg>
  );
};
