import type { Component, JSX } from 'solid-js';

export const Hourglass: Component<JSX.SvgSVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg width="1200pt" fill="currentColor" height="1200pt" viewBox="0 0 1200 1200" {...props}>
      <path d="m761.62 173.55v29.398h-323.4v-29.398h-58.801v58.801h29.398v264.75h58.801v-205.95h264.6v205.95h58.797v-264.75h29.551v-58.801z" />
      <path d="m732.23 761.85v147h-29.402v-29.402h-29.398v29.402h-29.402v-29.402h-29.398v29.402h-29.398v-29.402h-29.402v29.402h-29.398v-29.402h-29.402v29.402h-29.398v-205.95h-58.801v264.75h-29.398v58.801h58.801v-29.398h323.4v29.398h58.949v-58.801h-29.551v-264.75h-58.797z" />
      <path d="m673.43 702.9h58.801v-58.797h-58.801z" />
      <path d="m673.43 497.1v58.797h58.801v-58.797z" />
      <path d="m673.43 438.3h29.398v29.398h-29.398z" />
      <path d="m644.02 850.05h29.398v29.398h-29.398z" />
      <path d="m644.02 467.7h29.398v29.398h-29.398z" />
      <path d="m644.02 408.9h29.398v29.398h-29.398z" />
      <path d="m614.62 820.65h29.398v29.398h-29.398z" />
      <path d="m614.62 555.9v88.203h58.801v-88.203z" />
      <path d="m614.62 438.3h29.398v29.398h-29.398z" />
      <path d="m585.23 850.05h29.398v29.398h-29.398z" />
      <path d="m585.23 791.25h29.398v29.398h-29.398z" />
      <path d="m585.23 732.45h29.398v29.398h-29.398z" />
      <path d="m585.23 673.5h29.398v29.398h-29.398z" />
      <path d="m585.23 526.5h29.398v29.398h-29.398z" />
      <path d="m585.23 467.7h29.398v29.398h-29.398z" />
      <path d="m585.23 408.9h29.398v29.398h-29.398z" />
      <path d="m555.82 820.65h29.398v29.398h-29.398z" />
      <path d="m585.23 644.1v-88.203h-58.801v88.203z" />
      <path d="m555.82 438.3h29.398v29.398h-29.398z" />
      <path d="m526.43 850.05h29.398v29.398h-29.398z" />
      <path d="m526.43 467.7h29.398v29.398h-29.398z" />
      <path d="m526.43 408.9h29.398v29.398h-29.398z" />
      <path d="m526.43 702.9v-58.797h-58.801v58.797z" />
      <path d="m526.43 497.1h-58.801v58.797h58.801z" />
      <path d="m497.02 438.3h29.398v29.398h-29.398z" />
    </svg>
  );
};
