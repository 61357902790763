import { Show } from 'solid-js';
import { A } from '@solidjs/router';
import { Authenticated, CopyButton, Unauthenticated, ButtonPrimary, Layout, Title, Uppercase } from '~/components';
import { AnswerForm } from './components/AnswerForm';

function EasyAlgorithms() {
  return (
    <>
      <Title>Algorithms – Part 1</Title>
      <Uppercase>favorite user:</Uppercase>
      <p class="text-indigo-100">
        our website seems to be getting a lot of traffic! now we'd like to know who our most frequent visitor is.
      </p>
      <p class="text-indigo-100">all of the logs from the past day (your puzzle input) are encoded as follows:</p>
      <pre class="w-full overflow-x-auto text-nowrap bg-indigo-950/75 p-6">
        <code>[ISO datetime] [IP address] [request path] [response status code]</code>
      </pre>
      <p class="text-indigo-100">find the IP address of the user with the most visits, then enter it below.</p>
      <div class="group relative">
        <div class="absolute top-0 right-0 opacity-0 transition group-hover:opacity-100">
          <CopyButton input={() => getInputEasy()} />
        </div>
        <textarea
          readonly
          class="h-[320px] w-full resize-y overflow-scroll text-nowrap border-indigo-500 p-4 outline-none transition focus:bg-indigo-950/50"
          value={getInputEasy()}
          onClick={(e) => {
            if (e.currentTarget.selectionStart === e.currentTarget.selectionEnd) {
              e.currentTarget.select();
            }
          }}
        />
      </div>
    </>
  );
}
export function Algorithms() {
  return (
    <Layout>
      <div class="grid gap-4">
        <Unauthenticated>
          <EasyAlgorithms />
        </Unauthenticated>
        <Authenticated>
          {({ sideQuests }) => (
            <>
              <Show when={sideQuests.algorithms.hard || !sideQuests.algorithms.easy}>
                <EasyAlgorithms />
                <Show when={sideQuests.algorithms.easy}>
                  <hr class="border-indigo-500/30" />
                </Show>
              </Show>
              <Show
                when={sideQuests.algorithms.easy}
                fallback={<AnswerForm answerCharCount={11} difficulty="easy" category="algorithms" />}
              >
                <Title>Algorithms – Part 2</Title>
                <Uppercase>heavy traffic</Uppercase>
                <p class="text-indigo-100">
                  with all this traffic, we're wondering if we'll need to scale up our servers. to help us figure that
                  out, you'll need to find the 10-minute window from the past day's logs (your{' '}
                  <a href="/log.txt" download class="underline transition hover:text-white">
                    new puzzle input
                  </a>
                  ) that had the most traffic.
                </p>
                <p class="text-indigo-100">
                  enter the number of requests that appeared within that high-traffic 10-minute window below.
                </p>
                <Show when={!sideQuests.algorithms.hard}>
                  <AnswerForm answerCharCount={3} difficulty="hard" category="algorithms" />
                </Show>
              </Show>
            </>
          )}
        </Authenticated>
      </div>

      <footer class="border-t border-indigo-500/30 pt-8">
        <A href="/">
          <ButtonPrimary>
            <span class="font-dot not-italic">&lt;</span> back
          </ButtonPrimary>
        </A>
      </footer>
    </Layout>
  );
}

function getInputEasy() {
  return `2024-10-18T02:02:47.674Z 184.205.137.219 /submit 200
2024-10-18T02:07:53.658Z 151.226.8.67 /api/homepage 200
2024-10-18T02:09:32.735Z 228.103.44.53 /projects 200
2024-10-18T02:13:44.587Z 89.94.58.150 / 200
2024-10-18T02:15:48.056Z 245.215.188.145 / 200
2024-10-18T02:19:07.648Z 42.72.246.94 /api/status 200
2024-10-18T02:21:18.312Z 199.91.178.8 /api/results 200
2024-10-18T02:27:01.035Z 27.243.34.205 /api/status 200
2024-10-18T02:27:54.713Z 44.50.108.234 / 200
2024-10-18T02:35:29.921Z 44.75.129.225 /api/homepage 200
2024-10-18T02:35:54.632Z 40.104.99.204 /api/status 200
2024-10-18T02:43:19.667Z 56.99.139.90 /results 200
2024-10-18T02:44:52.207Z 166.34.111.192 /algorithms 200
2024-10-18T02:45:37.571Z 134.173.118.213 /hacking 200
2024-10-18T02:46:02.488Z 99.134.38.27 / 200
2024-10-18T02:51:57.149Z 27.243.34.205 / 200
2024-10-18T02:54:46.968Z 85.166.18.181 /hacking 200
2024-10-18T02:59:04.249Z 245.215.188.145 /forensics 200
2024-10-18T03:00:59.591Z 186.49.82.202 /api/status 200
2024-10-18T03:06:51.460Z 113.158.180.76 / 200
2024-10-18T03:07:00.518Z 111.60.235.74 / 200
2024-10-18T03:07:26.015Z 166.34.111.192 /api/results 200
2024-10-18T03:08:19.087Z 104.176.10.38 / 500
2024-10-18T03:09:43.442Z 85.166.18.181 /api/homepage 200
2024-10-18T03:10:29.508Z 181.215.82.8 / 500
2024-10-18T03:15:37.702Z 182.191.9.121 /api/status 200
2024-10-18T03:16:24.834Z 113.158.180.76 /logic 200
2024-10-18T03:23:58.248Z 89.94.58.150 / 200
2024-10-18T03:33:21.594Z 156.133.249.87 / 200
2024-10-18T03:34:27.996Z 162.209.140.123 / 200
2024-10-18T03:37:05.036Z 182.177.197.177 /api/status 200
2024-10-18T03:44:20.695Z 85.166.18.181 /api/homepage 200
2024-10-18T03:44:54.576Z 120.238.18.11 /projects 200
2024-10-18T03:46:41.477Z 182.88.99.245 /forensics 200
2024-10-18T03:47:58.651Z 44.50.108.234 /api/homepge 404
2024-10-18T03:49:04.623Z 191.186.192.130 / 500
2024-10-18T03:49:51.544Z 69.137.82.136 /api/homepage 200
2024-10-18T03:51:33.408Z 156.133.249.87 / 200
2024-10-18T03:52:28.141Z 58.222.100.68 / 200
2024-10-18T03:59:39.014Z 237.126.235.97 / 500
2024-10-18T04:02:04.034Z 176.66.47.71 / 200
2024-10-18T04:09:16.448Z 247.73.76.30 /api/status 200
2024-10-18T04:09:34.175Z 151.226.8.67 /api/homepage 200
2024-10-18T04:13:11.389Z 166.34.111.192 /api/status 200
2024-10-18T04:16:55.180Z 181.215.82.8 /hacking 200
2024-10-18T04:20:09.909Z 254.31.23.7 / 401
2024-10-18T04:24:30.093Z 184.205.137.219 / 200
2024-10-18T04:29:44.477Z 8.107.177.127 /api/status 200
2024-10-18T04:32:15.982Z 231.152.192.76 /api/homepage 200
2024-10-18T04:32:39.219Z 231.179.84.216 /api/results 200
2024-10-18T04:32:44.422Z 255.54.178.140 / 200
2024-10-18T04:36:05.817Z 83.194.100.175 / 200
2024-10-18T04:36:48.156Z 186.49.82.202 /results 200
2024-10-18T04:38:33.123Z 205.235.160.46 /api/status 200
2024-10-18T04:44:32.579Z 255.76.35.149 / 200
2024-10-18T04:50:02.069Z 192.168.0.1 /results 200
2024-10-18T04:51:08.904Z 201.21.134.224 /logic 200
2024-10-18T04:52:03.857Z 45.113.81.241 / 200
2024-10-18T04:52:38.075Z 55.84.135.70 /api/status 200
2024-10-18T04:56:44.876Z 184.88.57.227 /api/homepage 500
2024-10-18T04:56:51.081Z 177.49.159.104 /vote 200
2024-10-18T04:59:19.499Z 199.91.178.8 /logic 200
2024-10-18T05:03:57.503Z 162.209.140.123 /projects/:id 200
2024-10-18T05:06:45.617Z 8.107.177.127 /api/status 200
2024-10-18T05:12:40.146Z 151.97.207.101 / 200
2024-10-18T05:13:12.827Z 13.244.225.193 /graphics 200
2024-10-18T05:17:12.124Z 89.94.58.150 / 200
2024-10-18T05:22:36.203Z 192.168.0.1 / 200
2024-10-18T05:26:27.317Z 72.88.219.58 /vote 401
2024-10-18T05:32:32.242Z 55.84.135.70 /api/status 200
2024-10-18T05:32:45.264Z 1.33.199.231 / 200
2024-10-18T05:39:15.067Z 191.186.192.130 /results 200
2024-10-18T05:40:04.987Z 201.21.134.224 /api/status 200
2024-10-18T05:44:24.588Z 247.73.76.30 / 200
2024-10-18T05:49:14.230Z 125.12.124.50 / 200
2024-10-18T05:51:06.074Z 162.209.140.123 / 200
2024-10-18T05:52:54.087Z 83.194.100.175 / 200
2024-10-18T05:53:50.210Z 13.244.225.193 /api/results 200
2024-10-18T05:56:07.543Z 182.191.9.121 / 200
2024-10-18T05:58:06.752Z 139.241.22.53 /api/status 200
2024-10-18T06:00:28.431Z 188.52.10.26 / 200
2024-10-18T06:00:58.887Z 42.14.87.100 /prjects 404
2024-10-18T06:11:53.170Z 245.32.24.183 /api/homepage 200
2024-10-18T06:19:01.992Z 151.226.8.67 /hacking 200
2024-10-18T06:28:22.694Z 231.152.192.76 /vote 200
2024-10-18T06:32:25.035Z 121.216.97.95 /projects/:id 403
2024-10-18T06:38:42.211Z 169.224.164.41 /submit 200
2024-10-18T06:45:20.976Z 187.191.73.216 /projects 200
2024-10-18T06:45:53.398Z 177.49.159.104 / 200
2024-10-18T06:51:02.976Z 56.138.43.232 /api/homepage 200
2024-10-18T06:53:43.863Z 89.94.58.150 /api/status 200
2024-10-18T06:54:28.734Z 181.215.82.8 /projects/:id 200
2024-10-18T06:57:19.354Z 192.168.0.1 /algorithms 401
2024-10-18T07:03:28.961Z 228.103.44.53 /api/status 500
2024-10-18T07:04:35.082Z 83.194.100.175 /graphics 200
2024-10-18T07:04:36.156Z 104.176.10.38 /api/homepage 200
2024-10-18T07:08:14.648Z 61.248.2.8 /api/status 200
2024-10-18T07:15:16.923Z 1.33.199.231 /logic 400
2024-10-18T07:15:34.470Z 56.99.139.90 /vote 200
2024-10-18T07:20:31.558Z 127.80.89.47 /api/homepage 200
2024-10-18T07:20:58.762Z 169.224.164.41 /hacking 200
2024-10-18T07:23:19.675Z 181.215.82.8 /api/status 200
2024-10-18T07:24:22.803Z 56.99.139.90 / 200
2024-10-18T07:24:25.274Z 156.133.249.87 / 200
2024-10-18T07:28:16.069Z 228.103.44.53 / 200
2024-10-18T07:30:00.059Z 42.14.87.100 / 200
2024-10-18T07:32:34.592Z 184.205.137.219 / 200
2024-10-18T07:34:29.781Z 133.42.139.81 /api/status 200
2024-10-18T07:35:29.656Z 224.191.238.49 /graphics 200
2024-10-18T07:37:58.165Z 133.42.139.81 /api/status 200
2024-10-18T07:41:50.269Z 1.33.199.231 / 200
2024-10-18T07:42:01.376Z 192.168.0.1 /api/homepage 400
2024-10-18T07:44:33.522Z 133.42.139.81 /algorithms 200
2024-10-18T07:47:07.655Z 121.216.97.95 / 200
2024-10-18T07:48:43.295Z 83.194.100.175 / 401
2024-10-18T07:49:30.963Z 227.155.174.76 /api/homepage 403
2024-10-18T07:50:25.566Z 224.191.238.49 / 200
2024-10-18T07:51:46.779Z 92.201.65.137 / 200
2024-10-18T07:53:52.648Z 184.205.137.219 /projects/:id 200
2024-10-18T07:55:37.560Z 27.243.34.205 /api/homepage 200
2024-10-18T08:03:00.932Z 169.224.164.41 / 200
2024-10-18T08:03:25.800Z 39.199.70.213 /api/homepage 403
2024-10-18T08:07:41.472Z 104.176.10.38 /api/homepage 200
2024-10-18T08:09:47.998Z 44.50.108.234 /api/status 200
2024-10-18T08:14:40.925Z 37.46.232.79 /logic 200
2024-10-18T08:17:58.047Z 8.107.177.127 / 200
2024-10-18T08:19:40.452Z 184.88.57.227 /logic 200
2024-10-18T08:21:48.742Z 254.31.23.7 /api/homepage 200
2024-10-18T08:32:28.716Z 209.155.204.95 /logic 200
2024-10-18T08:34:51.610Z 104.176.10.38 / 401
2024-10-18T08:39:30.976Z 44.48.249.102 / 200
2024-10-18T08:39:51.904Z 56.138.43.232 / 403
2024-10-18T08:44:12.916Z 177.49.159.104 /api/status 200
2024-10-18T08:45:23.704Z 120.238.18.11 /api/status 200
2024-10-18T08:45:35.115Z 188.52.10.26 /results 200
2024-10-18T08:45:37.765Z 27.243.34.205 /hacking 200
2024-10-18T08:49:06.130Z 156.133.249.87 /projects/:id 200
2024-10-18T08:50:05.821Z 42.14.87.100 /projects/:id 200
2024-10-18T08:53:00.781Z 247.73.76.30 /projects/:id 200
2024-10-18T09:01:33.828Z 146.33.167.131 /api/homepage 200
2024-10-18T09:02:35.222Z 162.209.140.123 /api/authenticate 200
2024-10-18T09:03:29.235Z 27.243.34.205 /api/status 200
2024-10-18T09:03:39.553Z 85.166.18.181 / 200
2024-10-18T09:04:31.673Z 94.230.211.132 /puzzles 200
2024-10-18T09:13:41.628Z 124.61.99.142 /logic 200
2024-10-18T09:16:04.886Z 56.138.43.232 / 200
2024-10-18T09:18:35.786Z 93.205.161.101 / 200
2024-10-18T09:19:03.619Z 187.191.73.216 / 400
2024-10-18T09:19:44.354Z 58.222.100.68 / 200
2024-10-18T09:21:15.415Z 61.248.2.8 /graphics 200
2024-10-18T09:21:42.149Z 27.243.34.205 / 200
2024-10-18T09:22:09.901Z 224.191.238.49 / 403
2024-10-18T09:23:21.223Z 166.34.111.192 /api/status 200
2024-10-18T09:23:30.932Z 39.199.70.213 / 200
2024-10-18T09:24:44.678Z 181.215.82.8 /api/status 200
2024-10-18T09:25:40.059Z 72.88.219.58 /api/homepage 200
2024-10-18T09:28:36.618Z 209.155.204.95 / 400
2024-10-18T09:28:40.566Z 188.52.10.26 /results 200
2024-10-18T09:35:29.162Z 254.31.23.7 /api/homepage 200
2024-10-18T09:35:52.161Z 184.205.137.219 /projects 200
2024-10-18T09:44:18.145Z 94.230.211.132 / 200
2024-10-18T09:44:23.051Z 44.75.129.225 /api/status 200
2024-10-18T09:46:48.200Z 252.226.126.171 / 200
2024-10-18T09:52:19.119Z 184.88.57.227 / 200
2024-10-18T09:52:33.436Z 58.222.100.68 / 200
2024-10-18T09:52:43.170Z 104.176.10.38 /logic 200
2024-10-18T09:55:45.114Z 181.215.82.8 / 200
2024-10-18T09:58:53.954Z 235.16.2.98 / 200
2024-10-18T09:59:06.852Z 139.241.22.53 /api/homepage 200
2024-10-18T09:59:49.330Z 172.62.201.191 /api/status 200
2024-10-18T10:01:54.145Z 72.88.219.58 /api/status 200
2024-10-18T10:04:25.292Z 166.34.111.192 /puzzles 200
2024-10-18T10:09:55.880Z 1.33.199.231 /puzzles 200
2024-10-18T10:10:39.417Z 44.75.129.225 /api/status 200
2024-10-18T10:13:11.938Z 151.97.207.101 /vote 200
2024-10-18T10:14:48.650Z 72.88.219.58 /vote 200
2024-10-18T10:14:50.164Z 186.49.82.202 / 200
2024-10-18T10:18:02.977Z 61.248.2.8 /hacking 200
2024-10-18T10:24:58.665Z 186.49.82.202 /api/status 200
2024-10-18T10:27:57.869Z 184.205.137.219 / 500
2024-10-18T10:29:32.877Z 201.21.134.224 / 400
2024-10-18T10:30:08.200Z 46.255.21.62 /vote 200
2024-10-18T10:31:31.338Z 44.50.108.234 /algorithms 200
2024-10-18T10:32:30.894Z 42.72.246.94 /projects 200
2024-10-18T10:43:21.692Z 151.226.8.67 / 401
2024-10-18T10:48:42.990Z 37.46.232.79 /api/homepage 200
2024-10-18T10:53:15.872Z 104.117.58.175 /api/status 200
2024-10-18T10:54:38.524Z 44.48.249.102 / 200
2024-10-18T10:55:39.318Z 113.158.180.76 / 200
2024-10-18T10:57:12.247Z 227.155.174.76 /api/status 200
2024-10-18T10:57:49.439Z 85.166.18.181 /api/status 200
2024-10-18T10:58:49.567Z 254.31.23.7 / 200
2024-10-18T11:01:57.457Z 55.84.135.70 /vote 200
2024-10-18T11:05:30.240Z 201.76.28.133 /api/status 200
2024-10-18T11:05:57.110Z 151.97.207.101 / 200
2024-10-18T11:06:43.867Z 120.238.18.11 / 400
2024-10-18T11:10:11.632Z 182.191.9.121 / 200
2024-10-18T11:19:45.275Z 120.238.18.11 /logic 200
2024-10-18T11:19:56.948Z 245.32.24.183 / 200
2024-10-18T11:21:53.008Z 254.31.23.7 / 401
2024-10-18T11:23:36.009Z 94.230.211.132 / 200
2024-10-18T11:30:17.655Z 83.194.100.175 /api/homepage 200
2024-10-18T11:30:21.493Z 255.76.35.149 /results 200
2024-10-18T11:32:07.933Z 39.199.70.213 /api/status 200
2024-10-18T11:36:37.672Z 178.93.100.220 / 200
2024-10-18T11:37:55.973Z 191.194.99.235 /api/homepage 200
2024-10-18T11:39:02.746Z 121.152.183.17 / 200
2024-10-18T11:42:19.387Z 101.235.91.146 /api/homepage 200
2024-10-18T11:44:05.554Z 111.60.235.74 / 200
2024-10-18T11:54:09.675Z 176.66.47.71 /api/status 200
2024-10-18T11:54:36.990Z 151.226.8.67 /api/homepage 200
2024-10-18T11:57:04.886Z 127.80.89.47 /puzzles 200
2024-10-18T12:00:48.453Z 139.241.22.53 /hacking 200
2024-10-18T12:07:52.978Z 37.46.232.79 /api/homepage 400
2024-10-18T12:12:39.676Z 85.166.18.181 /api/homepage 200
2024-10-18T12:13:49.125Z 27.243.34.205 /vote 200
2024-10-18T12:18:10.015Z 201.76.28.133 /api/results 200
2024-10-18T12:20:18.817Z 111.60.235.74 /api/homepage 200
2024-10-18T12:25:03.717Z 238.124.41.108 /api/homepage 403
2024-10-18T12:27:38.965Z 245.32.24.183 /api/authenticate 200
2024-10-18T12:30:23.519Z 245.32.24.183 /api/homepage 200
2024-10-18T12:31:47.867Z 182.177.197.177 /api/status 200
2024-10-18T12:33:58.459Z 42.14.87.100 / 200
2024-10-18T12:34:05.121Z 98.0.133.71 /results 200
2024-10-18T12:38:21.034Z 83.194.100.175 /results 200
2024-10-18T12:45:58.749Z 182.88.99.245 / 200
2024-10-18T12:48:47.850Z 94.230.211.132 /api/authenticate 200
2024-10-18T12:49:35.822Z 178.93.100.220 /submit 500
2024-10-18T12:50:21.050Z 227.155.174.76 /api/authenticate 200
2024-10-18T12:54:24.409Z 166.34.111.192 /api/results 403
2024-10-18T12:54:57.777Z 192.168.0.1 / 200
2024-10-18T12:56:34.006Z 187.191.73.216 /api/homepage 200
2024-10-18T12:56:36.557Z 181.215.82.8 /api/authenticate 200
2024-10-18T13:02:24.112Z 254.31.23.7 /vote 200
2024-10-18T13:03:55.692Z 127.89.218.109 /api/authenticate 200
2024-10-18T13:07:00.552Z 89.94.58.150 / 200
2024-10-18T13:13:55.023Z 134.173.118.213 / 200
2024-10-18T13:15:27.648Z 172.62.201.191 /api/homepage 200
2024-10-18T13:20:44.553Z 46.236.181.164 /api/status 200
2024-10-18T13:21:12.904Z 23.142.237.22 /api/results 200
2024-10-18T13:21:46.373Z 236.60.108.81 /api/status 200
2024-10-18T13:26:19.674Z 162.209.140.123 / 200
2024-10-18T13:26:23.569Z 242.123.243.193 / 200
2024-10-18T13:33:24.657Z 42.14.87.100 / 401
2024-10-18T13:33:32.023Z 72.88.219.58 / 403
2024-10-18T13:34:43.011Z 72.88.219.58 / 200
2024-10-18T13:36:43.255Z 255.76.35.149 / 200
2024-10-18T13:40:06.964Z 61.248.2.8 /hacking 400
2024-10-18T13:44:05.153Z 182.88.99.245 / 200
2024-10-18T13:45:13.728Z 42.14.87.100 /hacking 200
2024-10-18T13:45:37.978Z 181.215.82.8 / 200
2024-10-18T13:48:33.415Z 178.93.100.220 / 200
2024-10-18T13:50:19.993Z 120.238.18.11 /api/homepage 200
2024-10-18T13:52:23.856Z 104.117.58.175 / 200
2024-10-18T13:53:09.831Z 39.199.70.213 /api/homepage 200
2024-10-18T13:53:10.254Z 27.243.34.205 / 200
2024-10-18T13:53:51.236Z 111.60.235.74 /forensics 200
2024-10-18T13:54:12.059Z 121.152.183.17 /logic 200
2024-10-18T13:58:36.340Z 182.191.9.121 / 200
2024-10-18T13:58:41.354Z 201.76.28.133 / 200
2024-10-18T13:59:05.268Z 245.215.188.145 / 200
2024-10-18T14:00:08.507Z 162.209.140.123 / 200
2024-10-18T14:01:56.501Z 245.32.24.183 / 200
2024-10-18T14:03:33.152Z 72.88.219.58 /api/x 404
2024-10-18T14:04:02.127Z 127.80.89.47 /algorithms 500
2024-10-18T14:10:50.225Z 254.31.23.7 / 200
2024-10-18T14:18:05.548Z 187.191.73.216 / 200
2024-10-18T14:18:08.238Z 176.66.47.71 / 200
2024-10-18T14:18:43.427Z 39.199.70.213 /puzzles 200
2024-10-18T14:19:49.334Z 192.168.0.1 /api/status 200
2024-10-18T14:23:12.441Z 245.215.188.145 / 200
2024-10-18T14:26:50.761Z 209.155.204.95 /projects 200
2024-10-18T14:28:05.958Z 166.34.111.192 / 200
2024-10-18T14:29:54.160Z 85.166.18.181 / 200
2024-10-18T14:31:39.384Z 23.142.237.22 /api/homepage 200
2024-10-18T14:47:06.195Z 120.238.18.11 /submit 400
2024-10-18T14:47:24.185Z 228.103.44.53 /api/status 200
2024-10-18T14:47:39.614Z 201.76.28.133 /api/homepage 200
2024-10-18T14:47:41.550Z 1.33.199.231 /api/homepage 200
2024-10-18T14:48:51.176Z 44.50.108.234 / 200
2024-10-18T14:49:01.296Z 228.103.44.53 / 200
2024-10-18T14:49:21.272Z 133.42.139.81 / 200
2024-10-18T14:52:10.013Z 224.191.238.49 /api/homepage 200
2024-10-18T14:59:52.639Z 181.215.82.8 /submit 200
2024-10-18T15:00:56.738Z 146.33.167.131 /api/status 200
2024-10-18T15:02:20.193Z 255.76.35.149 /forensics 200
2024-10-18T15:04:32.427Z 161.210.138.59 / 200
2024-10-18T15:07:20.190Z 182.88.99.245 / 200
2024-10-18T15:07:41.372Z 1.33.199.231 / 200
2024-10-18T15:09:17.566Z 191.194.99.235 /api/homepage 500
2024-10-18T15:11:40.998Z 113.158.180.76 /api/status 200
2024-10-18T15:11:53.025Z 58.222.100.68 /projects/:id 404
2024-10-18T15:14:10.525Z 61.248.2.8 /logic 401
2024-10-18T15:18:36.901Z 8.107.177.127 /forensics 200
2024-10-18T15:21:57.226Z 39.199.70.213 /forensics 401
2024-10-18T15:25:36.948Z 94.230.211.132 /api/homepage 200
2024-10-18T15:27:59.008Z 42.72.246.94 / 200
2024-10-18T15:31:37.407Z 191.194.99.235 /hacking 500
2024-10-18T15:31:56.071Z 245.215.188.145 /api/homepage 200
2024-10-18T15:32:45.324Z 39.199.70.213 /api/homepage 400
2024-10-18T15:33:24.270Z 242.123.243.193 /hacking 200
2024-10-18T15:43:10.652Z 121.216.97.95 / 200
2024-10-18T15:44:54.477Z 184.205.137.219 /algorithms 200
2024-10-18T15:45:31.936Z 217.3.198.92 /logic 200
2024-10-18T15:50:10.496Z 83.194.100.175 / 401
2024-10-18T15:51:34.217Z 40.104.99.204 /api/status 200
2024-10-18T15:52:19.866Z 55.84.135.70 /api/homepage 200
2024-10-18T15:52:41.981Z 146.33.167.131 /api/status 200
2024-10-18T15:54:32.965Z 127.80.89.47 /algorithms 200
2024-10-18T15:56:25.591Z 56.138.43.232 /api/status 200
2024-10-18T16:04:58.472Z 186.49.82.202 / 200
2024-10-18T16:05:35.612Z 172.62.201.191 /projects/:id 200
2024-10-18T16:07:33.372Z 130.219.236.162 / 200
2024-10-18T16:09:44.846Z 55.84.135.70 / 200
2024-10-18T16:10:12.421Z 188.52.10.26 /logic 200
2024-10-18T16:10:55.332Z 45.113.81.241 / 200
2024-10-18T16:12:14.766Z 133.42.139.81 /hacking 200
2024-10-18T16:14:08.736Z 72.88.219.58 /api/status 200
2024-10-18T16:14:24.857Z 188.52.10.26 / 200
2024-10-18T16:16:58.132Z 55.143.32.39 /logic 200
2024-10-18T16:21:37.211Z 209.155.204.95 / 400
2024-10-18T16:24:17.549Z 104.176.10.38 /api/status 200
2024-10-18T16:29:36.521Z 247.73.76.30 / 200
2024-10-18T16:34:09.713Z 242.123.243.193 /api/status 200
2024-10-18T16:35:49.591Z 212.171.216.28 / 200
2024-10-18T16:37:49.832Z 104.117.58.175 / 200
2024-10-18T16:37:56.233Z 94.230.211.132 /puzzles 200
2024-10-18T16:40:56.533Z 111.60.235.74 / 200
2024-10-18T16:41:22.751Z 42.14.87.100 / 200
2024-10-18T16:48:40.029Z 188.52.10.26 /api/homepage 200
2024-10-18T16:50:40.205Z 130.219.236.162 / 200
2024-10-18T16:50:54.243Z 162.209.140.123 /logic 200
2024-10-18T16:56:06.747Z 44.50.108.234 /puzzles 200
2024-10-18T17:00:27.933Z 245.215.188.145 /loogic 404
2024-10-18T17:00:45.616Z 85.166.18.181 /puzzzles 404
2024-10-18T17:01:22.383Z 56.138.43.232 /algorithms 200
2024-10-18T17:01:43.571Z 151.226.8.67 /api/homepage 200
2024-10-18T17:07:06.467Z 93.206.14.53 /api/results 200
2024-10-18T17:09:17.878Z 201.76.28.133 /api/homepage 200
2024-10-18T17:11:15.351Z 182.88.99.245 / 200
2024-10-18T17:12:42.971Z 130.219.236.162 /api/homepage 200
2024-10-18T17:16:07.677Z 254.31.23.7 / 200
2024-10-18T17:18:38.796Z 85.166.18.181 /api/status 401
2024-10-18T17:19:18.784Z 187.191.73.216 / 200
2024-10-18T17:21:06.405Z 227.155.174.76 /api/status 200
2024-10-18T17:28:52.571Z 187.191.73.216 /api/homepage 200
2024-10-18T17:30:44.137Z 227.155.174.76 / 200
2024-10-18T17:31:52.111Z 201.76.28.133 /api/authenticate 200
2024-10-18T17:37:01.013Z 227.155.174.76 /forensics 200
2024-10-18T17:39:50.926Z 111.60.235.74 /vote 403
2024-10-18T17:42:49.574Z 231.179.84.216 / 200
2024-10-18T17:45:50.808Z 133.42.139.81 / 200
2024-10-18T17:46:30.724Z 42.72.246.94 /submit 200
2024-10-18T18:01:48.220Z 231.179.84.216 /api/homepage 200
2024-10-18T18:08:14.124Z 134.173.118.213 /results 200
2024-10-18T18:12:30.293Z 231.179.84.216 /fornsics 404
2024-10-18T18:19:07.724Z 101.235.91.146 /api/results 200
2024-10-18T18:19:21.818Z 55.143.32.39 /api/status 200
2024-10-18T18:22:45.953Z 184.88.57.227 /api/homepage 200
2024-10-18T18:23:22.055Z 44.50.108.234 /algorithms 400
2024-10-18T18:24:08.900Z 93.206.14.53 /api/status 200
2024-10-18T18:25:43.425Z 227.155.174.76 /api/status 200
2024-10-18T18:28:34.003Z 93.205.161.101 /api/authenticate 200
2024-10-18T18:29:16.924Z 121.216.97.95 / 200
2024-10-18T18:39:09.332Z 55.143.32.39 /hacking 200
2024-10-18T18:45:15.647Z 130.219.236.162 /haxking 404
2024-10-18T18:47:05.559Z 201.76.28.133 /api/results 200
2024-10-18T18:49:53.261Z 182.177.197.177 /projects 200
2024-10-18T18:50:12.327Z 169.224.164.41 / 200
2024-10-18T18:50:32.380Z 55.84.135.70 / 200
2024-10-18T18:52:09.205Z 151.226.8.67 / 200
2024-10-18T18:58:09.990Z 201.76.28.133 /api/status 200
2024-10-18T19:00:44.712Z 182.191.9.121 /api/homepage 200
2024-10-18T19:03:32.177Z 134.173.118.213 / 200
2024-10-18T19:10:18.289Z 187.191.73.216 /api/status 200
2024-10-18T19:15:12.981Z 46.236.181.164 / 400
2024-10-18T19:16:37.800Z 56.138.43.232 /api/homepage 200
2024-10-18T19:19:51.875Z 120.238.18.11 /api/homepage 200
2024-10-18T19:20:12.168Z 44.50.108.234 / 200
2024-10-18T19:21:48.775Z 56.99.139.90 /api/homepage 200
2024-10-18T19:21:53.138Z 242.123.243.193 /api/homepage 200
2024-10-18T19:25:10.506Z 92.201.65.137 / 200
2024-10-18T19:25:53.882Z 252.226.126.171 /api/status 200
2024-10-18T19:26:08.129Z 44.50.108.234 /forensics 200
2024-10-18T19:29:29.121Z 113.158.180.76 /projects 200
2024-10-18T19:31:35.651Z 186.49.82.202 /hacking 200
2024-10-18T19:32:39.403Z 254.31.23.7 /api/status 200
2024-10-18T19:34:16.231Z 39.199.70.213 /api/status 200
2024-10-18T19:38:48.815Z 42.14.87.100 / 200
2024-10-18T19:40:19.824Z 235.25.255.11 /projects/:id 200
2024-10-18T19:45:15.420Z 27.243.34.205 /api/homepage 200
2024-10-18T19:47:59.295Z 134.173.118.213 /submit 200
2024-10-18T19:48:38.615Z 192.168.0.1 /api/results 200
2024-10-18T19:51:47.718Z 117.87.91.26 / 500
2024-10-18T19:55:23.850Z 42.72.246.94 / 200
2024-10-18T19:55:47.654Z 56.138.43.232 / 200
2024-10-18T19:56:14.468Z 133.42.139.81 /api/status 200
2024-10-18T19:59:20.998Z 178.93.100.220 /api/homepage 200
2024-10-18T20:01:36.958Z 69.137.82.136 /api/status 200
2024-10-18T20:08:53.574Z 13.244.225.193 /puzzles 200
2024-10-18T20:10:33.657Z 181.215.82.8 /api/results 200
2024-10-18T20:12:26.985Z 227.155.174.76 /logic 200
2024-10-18T20:16:27.798Z 235.16.2.98 / 200
2024-10-18T20:16:35.407Z 56.138.43.232 /vote 200
2024-10-18T20:16:48.723Z 217.3.198.92 / 200
2024-10-18T20:22:27.932Z 176.66.47.71 /api/status 200
2024-10-18T20:32:53.750Z 44.75.129.225 / 200
2024-10-18T20:33:41.247Z 205.235.160.46 / 200
2024-10-18T20:36:12.288Z 191.186.192.130 / 200
2024-10-18T20:37:55.043Z 199.91.178.8 /puzzles 200
2024-10-18T20:41:26.720Z 151.226.8.67 / 200
2024-10-18T20:51:31.944Z 235.25.255.11 /api/homepage 200
2024-10-18T20:52:50.309Z 124.61.99.142 / 400
2024-10-18T20:53:32.463Z 113.158.180.76 /logic 200
2024-10-18T20:53:45.850Z 247.73.76.30 / 200
2024-10-18T21:05:32.535Z 133.42.139.81 /logic 200
2024-10-18T21:07:27.259Z 169.224.164.41 /api/homepage 403
2024-10-18T21:09:05.726Z 192.168.0.1 / 200
2024-10-18T21:13:08.097Z 61.248.2.8 /hacking 200
2024-10-18T21:15:20.389Z 146.33.167.131 /puzzles 200
2024-10-18T21:16:45.640Z 177.49.159.104 /algorithms 200
2024-10-18T21:18:05.817Z 252.226.126.171 /api/results 200
2024-10-18T21:18:11.114Z 156.133.249.87 / 200
2024-10-18T21:19:13.858Z 56.138.43.232 /projects 200
2024-10-18T21:22:50.079Z 151.97.207.101 / 200
2024-10-18T21:23:46.459Z 245.221.172.140 /projects 200
2024-10-18T21:24:52.141Z 55.84.135.70 / 200
2024-10-18T21:26:57.879Z 111.60.235.74 /api/status 200
2024-10-18T21:29:45.731Z 13.244.225.193 / 200
2024-10-18T21:36:04.985Z 39.199.70.213 /vote 200
2024-10-18T21:36:37.527Z 56.138.43.232 /projects 200
2024-10-18T21:38:42.838Z 44.48.249.102 / 200
2024-10-18T21:41:53.936Z 55.84.135.70 /api/homepage 200
2024-10-18T21:42:16.074Z 255.54.178.140 /api/homepage 403
2024-10-18T21:43:11.725Z 1.33.199.231 /results 200
2024-10-18T21:46:27.260Z 187.191.73.216 / 200
2024-10-18T21:48:19.380Z 177.49.159.104 / 200
2024-10-18T21:49:09.013Z 247.73.76.30 /api/homepage 200
2024-10-18T21:51:55.813Z 92.201.65.137 /api/status 200
2024-10-18T21:51:56.864Z 46.236.181.164 /puzzles 200
2024-10-18T21:57:00.828Z 177.49.159.104 /api/status 200
2024-10-18T21:57:01.556Z 42.14.87.100 /api/homepage 200
2024-10-18T22:00:37.495Z 13.244.225.193 /api/v2/status 404
2024-10-18T22:03:48.619Z 83.194.100.175 /api/homepage 200
2024-10-18T22:04:58.310Z 146.33.167.131 / 200
2024-10-18T22:06:08.226Z 228.103.44.53 /reslts 404
2024-10-18T22:06:10.015Z 245.32.24.183 /results 200
2024-10-18T22:19:24.993Z 242.123.243.193 / 400
2024-10-18T22:19:54.001Z 254.31.23.7 / 200
2024-10-18T22:29:54.146Z 177.85.68.18 /api/homepage 200
2024-10-18T22:31:45.384Z 133.42.139.81 / 200
2024-10-18T22:32:34.608Z 209.155.204.95 /api/homepage 200
2024-10-18T22:34:11.767Z 191.194.99.235 /hacking 200
2024-10-18T22:35:41.151Z 104.176.10.38 / 200
2024-10-18T22:36:26.472Z 231.179.84.216 /puzzles 200
2024-10-18T22:37:00.967Z 199.91.178.8 /hacking 200
2024-10-18T22:38:33.790Z 134.173.118.213 / 200
2024-10-18T22:40:19.915Z 120.238.18.11 / 200
2024-10-18T22:43:21.188Z 201.76.28.133 /api/homepage 200
2024-10-18T22:48:36.560Z 8.107.177.127 /hacking 200
2024-10-18T22:48:55.653Z 124.61.99.142 /api/homepage 200
2024-10-18T22:55:02.570Z 134.173.118.213 / 200
2024-10-18T22:59:12.559Z 184.205.137.219 / 200
2024-10-18T23:02:13.518Z 27.243.34.205 / 200
2024-10-18T23:03:54.577Z 56.99.139.90 / 200
2024-10-18T23:05:59.569Z 42.72.246.94 / 200
2024-10-18T23:06:12.041Z 184.205.137.219 / 200
2024-10-18T23:07:53.785Z 1.33.199.231 /api/status 200
2024-10-18T23:19:04.053Z 184.88.57.227 / 403
2024-10-18T23:20:35.414Z 231.179.84.216 /api/homepage 200
2024-10-18T23:21:44.589Z 40.104.99.204 /api/status 200
2024-10-18T23:24:06.061Z 235.16.2.98 /api/homepage 200
2024-10-18T23:32:09.079Z 101.235.91.146 / 200
2024-10-18T23:37:40.277Z 111.60.235.74 / 200
2024-10-18T23:38:30.087Z 139.241.22.53 / 200
2024-10-18T23:40:20.868Z 113.158.180.76 /api/status 400
2024-10-18T23:48:21.979Z 201.76.28.133 / 200
2024-10-18T23:57:04.646Z 37.46.232.79 /projects/:id 200
2024-10-19T00:03:26.781Z 178.93.100.220 /vote 200
2024-10-19T00:07:19.207Z 121.152.183.17 / 200
2024-10-19T00:08:06.080Z 192.168.0.1 /api/homepage 200
2024-10-19T00:10:33.756Z 127.80.89.47 /api/status 200
2024-10-19T00:11:08.310Z 201.21.134.224 / 200
2024-10-19T00:13:10.273Z 104.117.58.175 / 200
2024-10-19T00:14:54.697Z 186.49.82.202 /api/status 200
2024-10-19T00:17:05.340Z 111.60.235.74 /api/results 200
2024-10-19T00:19:07.312Z 192.168.0.1 / 200
2024-10-19T00:20:28.337Z 104.176.10.38 / 200
2024-10-19T00:21:21.067Z 182.88.99.245 /algorithms 200
2024-10-19T00:27:54.966Z 242.123.243.193 /logic 200
2024-10-19T00:27:55.685Z 182.191.9.121 / 200
2024-10-19T00:38:16.033Z 199.91.178.8 /graphics 200
2024-10-19T00:38:34.871Z 245.32.24.183 / 200
2024-10-19T00:39:03.307Z 242.123.243.193 /api/status 200
2024-10-19T00:47:38.278Z 245.215.188.145 /api/homepage 500
2024-10-19T00:48:50.734Z 94.230.211.132 /projects/:id 200
2024-10-19T00:50:18.433Z 236.60.108.81 /algorithms 200
2024-10-19T00:51:43.741Z 133.42.139.81 /projects/:id 200
2024-10-19T00:55:43.348Z 178.93.100.220 / 200
2024-10-19T00:56:28.397Z 191.194.99.235 /api/results 403
2024-10-19T00:57:16.637Z 117.87.91.26 / 401
2024-10-19T01:00:38.772Z 104.117.58.175 / 200
2024-10-19T01:02:08.902Z 85.166.18.181 / 200
2024-10-19T01:04:22.782Z 231.179.84.216 / 200
2024-10-19T01:06:46.092Z 55.143.32.39 /algorithms 200
2024-10-19T01:09:55.108Z 130.219.236.162 / 200
2024-10-19T01:09:58.073Z 120.238.18.11 /puzzles 200
2024-10-19T01:12:04.988Z 161.210.138.59 /vote 200
2024-10-19T01:15:39.940Z 231.179.84.216 /forensics 200
2024-10-19T01:17:51.128Z 99.134.38.27 /api/status 200
2024-10-19T01:23:47.094Z 121.216.97.95 / 200
2024-10-19T01:25:22.093Z 39.199.70.213 /api/homepage 200
2024-10-19T01:25:57.711Z 42.14.87.100 / 200
2024-10-19T01:32:10.471Z 192.168.0.1 / 200
2024-10-19T01:32:50.887Z 242.123.243.193 /graphics 403
2024-10-19T01:33:06.340Z 94.230.211.132 / 200
2024-10-19T01:37:23.217Z 184.205.137.219 /puzzles 200
2024-10-19T01:41:58.982Z 1.33.199.231 /api/status 200
2024-10-19T01:43:28.713Z 113.158.180.76 /projects/:id 200
2024-10-19T01:44:13.444Z 72.88.219.58 /logic 200
2024-10-19T01:45:49.920Z 184.88.57.227 /submit 200`;
}

// function findMaxRequests(input: string): number {
//   // Split the logs into lines
//   const lines = input.trim().split('\n');
//   // Initialize an array to hold the timestamps
//   const timestamps: number[] = [];

//   for (let line of lines) {
//     line = line.trim();
//     if (line.length === 0) continue;
//     // Split the line by space
//     const [dateTimeStr] = line.split(' ');
//     // Parse the datetime string
//     const date = new Date(dateTimeStr!);
//     const timestamp = date.getTime();
//     timestamps.push(timestamp);
//   }

//   // Sort the timestamps
//   timestamps.sort((a, b) => a - b);

//   // Initialize variables for sliding window
//   let maxCount = 0;
//   let start = 0;
//   const n = timestamps.length;

//   for (let end = 0; end < n; end++) {
//     // Move the start pointer to maintain a 10-minute window
//     while (timestamps[end]! - timestamps[start]! >= 10 * 60 * 1000) {
//       start++;
//     }
//     const count = end - start + 1;
//     if (count > maxCount) {
//       maxCount = count;
//     }
//   }

//   return maxCount;
// }

// console.log(findMaxRequests(getInputHard()));
